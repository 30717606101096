.spriteImg {
  &::before {
    content: "";
    background: url('../public/images/spritImg.png');
    background-repeat: no-repeat;display: block;
    margin: auto;
  }

  &.icon-plane {
    &:before {
      background-position: -16px -15px;
      width: 51px;
      height: 39px;
      
    }

    &:hover {
      &:before {
      background-position: -74px -15px;
    }
  }
  }

  &.icon-banking {
    &:before {
      background-position: -141px -16px;
      width: 48px;
      height: 48px;
    }
    &:hover {
      &:before {
      background-position: -197px -16px;
    }
  }
  }


  &.icon-city {
    &:before {
      background-position:-265px -16px;
      width: 51px;
      height: 39px;    
    }
    &:hover {
      &:before {

      background-position:  -321px -16px;
    }
  }
  }

  &.icon-corrections {
    &:before {
      background-position:-384px -16px;
      width: 41px;
      height: 41px;
     
    }
    &:hover {
      &:before {

      background-position:   -434px -16px;
    }
  }
  }
  &.icon-education{
    &:before {
      background-position:-484px -17px;
      width: 51px;
      height: 47px;
     
    }
    &:hover {
      &:before {

      background-position:-543px -17px;
    }
  }
  }
  &.icon-industial{
    &:before {
      background-position: -9px -68px;
      width: 51px;
      height: 51px;
     
    }
    &:hover {
      &:before {

      background-position: -70px -68px;
    }
  }
  }

  &.icon-transport {
    &:before {
      background-position:  -141px -77px;
      width: 48px;
      height: 32px;
     
    }
    &:hover {
      &:before {

      background-position: -196px -77px;
    }
  }
  }

  &.icon-retail {
    &:before {
      background-position:  -260px -74px;
      width: 48px;
      height: 40px;
     
    }
    &:hover {
      &:before {

      background-position: -316px -74px;
    }
  }
  }

}

.overIcon{
  &:hover,&.active{
    color: #2aa7df !important;
    transition: 1s;
.spriteImg {
&::before{
  transition: 1s;
}

  &.icon-plane {
 
      &:before {
      background-position: -74px -15px;
    
  }
  }

  &.icon-banking {

      &:before {
      background-position: -197px -16px;
    
  }
  }


  &.icon-city {

 
      &:before {

      background-position:  -321px -16px;
  
  }
  }

  &.icon-corrections {

 
      &:before {

      background-position:   -434px -16px;
    
  }
  }
  &.icon-education{
   

      &:before {

      background-position:-543px -17px;
   
  }
  }
  &.icon-industial{

 
      &:before {

      background-position: -70px -68px;
   
  }
  }

  &.icon-transport {

 
      &:before {

      background-position: -196px -77px;
   
  }
  }

  &.icon-retail {

   
      &:before {

      background-position: -316px -74px;
    }

  }

}
}
}